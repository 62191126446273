import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import { Grid, Typography, Hidden, useTheme, useMediaQuery, Box, Button } from '@material-ui/core';
import moment from 'moment';
import PaperDate from '../../components/paper-date';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import colors from '../../constants/colors';
import CustomLink from '../../components/custom-link';
const KnowledgeHubPdfInnerPage = ({ pageContext: { _knowledgeHub } }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Layout>
      <SEO
        title={_knowledgeHub.seoTitle ? _knowledgeHub.seoTitle : null}
        description={_knowledgeHub.seoDescription ? _knowledgeHub.seoDescription : null}
        keywords={_knowledgeHub.seoKeywords ? _knowledgeHub.seoKeywords : null}
        lang='en'
      />
      <SectionContainerLayoutWithFilter isViewAll baseLink='/knowledge-hub' title='KNOWLEDGE HUB'>
        <Grid container>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={1}>
              <PaperDate
                day={moment(_knowledgeHub.date).format('DD')}
                month={moment(_knowledgeHub.date).format('MMM').toUpperCase()}
                isTimeSeparate={false}
              />
            </Grid>
            <Box clone pt={isMobile ? 2 : 0}>
              <Grid item xs={12} sm={10}>
                <Typography variant='h6' color='textPrimary' align={isMobile ? 'left' : 'center'}>
                  {_knowledgeHub.title}
                </Typography>
              </Grid>
            </Box>
            <Hidden smDown>
              {_knowledgeHub.type === 'pdf' && _knowledgeHub.file.find((_file) => _file.type === 'application/pdf') ? (
                <Grid item xs={12} sm={1} align='right'>
                  <a
                    className='MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary text-decoration-none'
                    href={`${process.env.GATSBY_CMS_FILES_API_URL}/pdf/${
                      _knowledgeHub.file.find((_file) => _file.type === 'application/pdf').code
                    }.pdf`}
                    target='_blank'
                    rel='noreferrer'
                    style={{ margin: '5px' }}
                    download>
                    <ArrowDownwardIcon />
                  </a>
                  <Typography paragraph color='textPrimary'>
                    {_knowledgeHub.size
                      ? _knowledgeHub.size
                      : Math.round(_knowledgeHub.file.find((f) => f.type === 'application/pdf').size * 10 ** -6)}{' '}
                    MB
                  </Typography>
                </Grid>
              ) : null}
            </Hidden>
          </Grid>
          <Grid item xs={12}>
            <Box my={5}>
              <Typography paragraph color='textPrimary'>
                DESCRIPTION
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography paragraph color='textSecondary'>
              {_knowledgeHub.description}
            </Typography>
          </Grid>
          {_knowledgeHub.type === 'pdf' && _knowledgeHub.file.find((_file) => _file.type === 'application/pdf') ? (
            <>
              <Hidden mdUp>
                <Grid item xs={12} align='center'>
                  <Box my={3} width={1}>
                    <CustomLink
                      type={8}
                      external
                      linkText='VIEW ENTIRE PDF'
                      linkUrl={`${process.env.GATSBY_CMS_FILES_API_URL}/pdf/${
                        _knowledgeHub.file.find((_file) => _file.type === 'application/pdf').code
                      }`}
                    />
                  </Box>
                </Grid>
              </Hidden>
              <Grid item xs={12}>
                {_knowledgeHub.file.find((_file) => _file.type === 'application/pdf') ? (
                  // <iframe
                  //   title={_knowledgeHub.title}
                  //   src={`https://docs.google.com/viewerng/viewer?url=${process.env.GATSBY_CMS_FILES_API_URL}/${
                  //     _knowledgeHub.file.find((_file) => _file.type === 'application/pdf').code
                  //   }.pdf&embedded=true`}
                  //   type='application/pdf'
                  //   width='100%'
                  //   height='900px'
                  // />

                  // <object
                  //   type='application/pdf'
                  //   width='100%'
                  //   height='600px'
                  //   data={`${process.env.GATSBY_CMS_FILES_API_URL}/stream/pdf/${
                  //     _knowledgeHub.file.find((_file) => _file.type === 'application/pdf').code
                  //   }.pdf`}>
                  //   alt:{' '}
                  //   <a
                  //     href={`${process.env.GATSBY_CMS_FILES_API_URL}/stream/pdf${
                  //       _knowledgeHub.file.find((_file) => _file.type === 'application/pdf').code
                  //     }.pdf`}>
                  //     PDF FILE
                  //   </a>
                  // </object>
                  <embed
                    src={`${process.env.GATSBY_CMS_FILES_API_URL}/stream/pdf/${
                      _knowledgeHub.file.find((_file) => _file.type === 'application/pdf').code
                    }.pdf#toolbar=0`}
                    width='100%'
                    height='600px'
                  />
                ) : // <embed
                //   src={`${process.env.GATSBY_CMS_FILES_API_URL}/${
                //     _knowledgeHub.file.find((_file) => _file.type === 'application/pdf').code
                //   }.pdf`}
                //   type='application/pdf'
                // />
                null}
              </Grid>
            </>
          ) : _knowledgeHub.type === 'article' ? (
            <Grid item xs={12}>
              <Box fontSize='body1.fontSize' style={{ color: colors.text.secondary.dark }}>
                <div dangerouslySetInnerHTML={{ __html: _knowledgeHub.article }} />
              </Box>
            </Grid>
          ) : _knowledgeHub.type === '' ? null : null}
        </Grid>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
};

export default KnowledgeHubPdfInnerPage;
